import React, { Fragment } from "react";
import PropTypes from "prop-types";

const GasPayOffBanner = ({ title, text }) => {
  if (!title) return null;

  return (
    <div
      className={
        "relative bg-exploratory-green-400 md:bg-bottom bg-no-repeat bg-cover md:bg-auto bg-[url('/images/banners/heat-pump-banner-mobile.jpg')] md:bg-[url('/images/banners/heat-pump-banner.jpg')]"
      }
    >
      <div className={"flex flex-col justify-end md:justify-center py-8 md:py-20 container mx-auto max-w-screen-lg min-h-[370px] md:min-h-[280px]"}>
        <div className={"flex flex-col px-4 text-center md:text-left"}>
          <h1 className={"text-H3 sm:text-H1 leading-tight mb-2 text-white"} dangerouslySetInnerHTML={{ __html: title }} />
          <p className={"text-body md:text-subhead mb-0 text-white"} dangerouslySetInnerHTML={{ __html: text }} />
        </div>
      </div>
    </div>
  );
};

GasPayOffBanner.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

export default GasPayOffBanner;
