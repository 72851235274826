import { KWH } from "../../../constants";
import accounting from "accounting";
import { convertFromToUnits } from "../../../formUtils";

export const MAX_CONSUMPTION_KWH = 63999;
export const MIN_CONSUMPTION_KWH = 300;

export const formatConsumption = (num, precision) => {
  return accounting.formatMoney(num, "", precision, " ", ",");
};

export const consumptionValidation = (value, unitType) => {
  let valueInKWh = convertFromToUnits(Number(value), unitType, KWH);

  return valueInKWh >= MIN_CONSUMPTION_KWH && valueInKWh <= MAX_CONSUMPTION_KWH;
};
