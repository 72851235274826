import React from "react";
import cx from "classnames";
import * as graphStyles from "./BarGraph3D.module.css";
import { ELECTRICITY, GAS } from "../../../../constants";
import { IconElectricity, IconGas } from "../../../Icons";

const BarGraph3D = props => {
  const { type, scale = 0.5 } = props;

  const isElectricity = type === ELECTRICITY;
  const isGas = type === GAS;

  return (
    <div>
      <div
        className={cx({
          [graphStyles.barWrapper]: true,
        })}
      >
        <div className={graphStyles.barContainer} style={{ height: `${70 + 90 * scale}px` }}>
          {" "}
          {/* min-height: 70px, max-height: 160px */}
          <div className={graphStyles.barInner} />
          <div
            className={cx({
              [graphStyles.barForeground]: true,
              [graphStyles.barForegroundELE]: isElectricity,
              [graphStyles.barForegroundGAS]: isGas,
            })}
          >
            <div className={"absolute bottom-5 left-1/2 -translate-x-1/2 scale-x-[-1]"}>
              {type === ELECTRICITY && <IconElectricity />}
              {type === GAS && <IconGas />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BarGraph3D;
