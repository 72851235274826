import React from "react";
import IconLightBubble from "../../../Icons/IconLightBubble";
import { formatPrice } from "../../../../formUtils";
import accounting from "accounting";

const getMonthlySavings = (gasPrice, elePrice) => Math.round((gasPrice - elePrice) / 12);
const investmentRepaidIn = (heatpumpPrice, gasPrice, elePrice) => Math.ceil(heatpumpPrice / (gasPrice - elePrice));

const HeatPumpSummary = ({ heatPumpPrice, calcResult: { gasPrice, elePrice } }) => {
  const electricityCheaper = gasPrice - elePrice > 0;

  return electricityCheaper ? (
    <div className={"relative bg-resource-green-100 text-exploratory-green-400 px-4 mb-12 md:mb-24"}>
      <div className={"flex flex-col justify-center py-6 md:py-16 container mx-auto max-w-screen-lg max-w-[620px]"}>
        <h2 className={"text-H3 flex items-center mb-6"}>
          <IconLightBubble width={50} />
          <span className={"inline ml-3"}>Shrnutí</span>
        </h2>

        <p className={"mb-6 text-bigForms"}>
          Přechodem na&nbsp;tepelné čerpadlo dle aktuálně platného základního ceníku MND můžete ušetřit{" "}
          {accounting.formatMoney(gasPrice - elePrice, "", 0, " ", ",")}&nbsp;Kč ročně.
        </p>
        <p className={"mb-6 text-bigForms"}>
          Při rozhodování o&nbsp;přechodu na tepelné čerpadlo{" "}
          <strong>je nutné počítat s&nbsp;vysokými pořizovacími náklady, v&nbsp;našem případě {formatPrice(heatPumpPrice)}.</strong>
        </p>
        <p className={"mb-6 text-bigForms"}>
          Díky&nbsp;tomu při výše uvedené roční spotřebě{" "}
          <strong>doba návratnosti investice činí až {investmentRepaidIn(heatPumpPrice, gasPrice, elePrice)}&nbsp;let,</strong> přičemž průměrná
          životnost tepelného čerpadla (vzduch-voda) je 15&nbsp;let.
        </p>
        <p className={"mb-6 text-bigForms"}>S aktuálním trendem klesajících cen energií se doba návratnosti investice může ještě&nbsp;více prodloužit.</p>
        <p className={"mb-6 text-bigForms"}>
          <strong>
            Měsíčně si tak můžete zajistit úsporu jen {accounting.formatMoney(getMonthlySavings(gasPrice, elePrice), "", 0, " ", ",")}&nbsp;Kč.
          </strong>
        </p>
        <p className={"mb-0 text-bigForms"}>
          Navíc tepelné čerpadlo technologie vzduch-voda je vhodné pouze pro některé typy nemovitostí, proto vám doporučujeme nechat&nbsp;si vždy připravit
          od&nbsp;dodavatele individuální nabídku s&nbsp;optimálním technologickým řešením.
        </p>
      </div>
    </div>
  ) : null;
};

export default HeatPumpSummary;
