import React from "react";
import PropTypes from "prop-types";

const Select = props => {
  const { name, id, label, options, onChange, value } = props;

  const htmlForStr = !!id ? id : name;

  return (
    <div className={"flex flex-col"}>
      {label && (
        <label htmlFor={htmlForStr} className="block font-semibold text-exploratory-green-400">
          {label}
        </label>
      )}

      <div className="mt-1">
        <select
          id={htmlForStr}
          name={name}
          onChange={onChange}
          value={value}
          className="block w-full font-semibold border-0 bg-light-grey text-exploratory-green-400 focus:ring-transparent"
          // defaultValue={!!defaultValue ? defaultValue : options[1].value}
        >
          {!!options &&
            options.map(option => (
              <option key={option.value} value={option.value}>
                {option.title}
              </option>
            ))}
        </select>
      </div>
    </div>
  );
};

Select.propTypes = {};

Select.defaultProsps = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ),
  defaultValue: PropTypes.array,
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
};

export default Select;
