import React, { Fragment, useState } from "react";
import IconInfo from "../../../../style/icons/IconInfo";
import CalcGraphResult from "./CalcGraphResult";
import { IconChevronDown } from "../../../Icons";

const HeatPumpCalcResult = ({ heatPumpPrice, calcResult }) => {
  const [showDetailResult, setShowDetailResult] = useState(false);

  return (
    <div id={"calcResult"} className={"mx-auto max-w-[750px] pt-16"}>
      <h2 className={"text-h2 text-center text-exploratory-green-400"}>Kolik zaplatíte za topení v prvním roce?</h2>

      <CalcGraphResult showDetailResult={showDetailResult} heatPumpPrice={heatPumpPrice} calcResult={calcResult} />

      <p className={"text-body text-exploratory-green-400"}>
        <span className={"inline-block align-middle h-5"}>
          <IconInfo width={20} height={20} color={"#ACAEB0"} />
        </span>{" "}
        <span className={"inline align-middle"}>
          Modelový převod spotřeby zemního plynu na spotřebu elektřiny probíhá na základě průměrného ročního topného faktoru tepelného čerpadla (technologie
          vzduch-voda). Propočet zohledňuje domácnost, ve které žije čtyřčlenná rodina (2 dospělí a 2 děti školou povinné). Jejich výchozí nastavená teplota v
          domácnosti je 20&nbsp;°C. Pro výpočet platby za elektřinu počítáme s přiřazením distribuční sazby D57d a spínáním tepelného čerpadla pouze v době
          platnosti nízkého tarifu. (Zdroj: TZB-info)
        </span>
      </p>

      <div className={"flex justify-center"}>
        <button
          className={"flex justify-center text-exploratory-green-400 text-small font-semibold underline hover:no-underline"}
          onClick={() => setShowDetailResult(prevShowDetailResult => !prevShowDetailResult)}
        >
          {!showDetailResult ? (
            <Fragment>
              <span>Zobrazit podrobnosti výpočtu</span>{" "}
              <span className={"inline-block align-middle ml-2"}>
                <IconChevronDown />
              </span>
            </Fragment>
          ) : (
            <Fragment>
              <span>Skrýt podrobnosti výpočtu</span>{" "}
              <span className={"inline-block align-middle ml-2 rotate-180 flex"}>
                <IconChevronDown />
              </span>
            </Fragment>
          )}
        </button>
      </div>
    </div>
  );
};

HeatPumpCalcResult.propTypes = {};

export default HeatPumpCalcResult;
