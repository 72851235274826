import React from "react";

const EnergyPump = () => {
  return (
    <svg width="108" height="84" viewBox="0 0 108 84" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M29.5377 74.25H85.3571L88.5 84L95.5 81V76L97.5 75C103.5 70 107.5 70.2 107.5 65.25V9C107.5 4.05 103.5 0 98.6111 0H16.3889C11.5 0 10.5 0.5 4.5 4.49977L7.5 65.25C7.5 70.2 11.5 74.25 16.3889 74.25H19.7222L22.5 84L29.5198 81L29.5357 74.25H29.5377Z"
        fill="#595A5B"
      />
      <path d="M16.3889 0H98.6111C100.983 0 103.146 0.953229 104.744 2.5L97 6.5L7.5 65.25L4.5 4.49977C10.5 0.5 11.5 0 16.3889 0Z" fill="#E6E6E7" />
      <path
        d="M22.5377 77.25H78.3571L78.373 84H88.5L88.4841 77.25H91.6111C96.5 77.25 100.5 73.2 100.5 68.25V12C100.5 7.05 96.5 3 91.6111 3H9.38889C4.5 3 0.5 7.05 0.5 12V68.25C0.5 73.2 4.5 77.25 9.38889 77.25H12.7222L12.6984 84H22.5198L22.5357 77.25H22.5377Z"
        fill="#ACAEB0"
      />
      <path
        d="M33 60C44.3218 60 53.5 50.8218 53.5 39.5C53.5 28.1782 44.3218 19 33 19C21.6782 19 12.5 28.1782 12.5 39.5C12.5 50.8218 21.6782 60 33 60Z"
        stroke="white"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M85.5 58H65.5" stroke="white" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M85.5 19H65.5" stroke="white" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M85.5 32H65.5" stroke="white" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M85.5 45H65.5" stroke="white" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M33.0001 43.3019C35.3499 43.3019 37.2548 41.397 37.2548 39.0472C37.2548 36.6974 35.3499 34.7925 33.0001 34.7925C30.6503 34.7925 28.7454 36.6974 28.7454 39.0472C28.7454 41.397 30.6503 43.3019 33.0001 43.3019Z"
        stroke="white"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M36.1177 35.566C37.1521 33.3422 37.7843 31.1762 37.9854 29.0535C38.3015 26.6997 36.8935 24.7358 33 24.7358C29.1065 24.7358 27.6985 26.6997 28.0146 29.0535C28.2301 31.1762 28.8479 33.3567 29.8823 35.566"
        stroke="white"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M34.9341 43.9187C36.2894 45.8819 37.8123 47.4525 39.4889 48.6725C41.3052 50.0888 43.6385 49.8364 45.5247 46.555C47.4109 43.2737 46.4609 41.1282 44.3371 40.2447C42.4509 39.3893 40.3272 38.8705 37.966 38.6602"
        stroke="white"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M28.0356 38.6602C25.6757 38.8565 23.5531 39.3893 21.6679 40.2447C19.5453 41.1142 18.5817 43.2597 20.4809 46.555C22.3661 49.8364 24.6981 50.0888 26.5135 48.6725C28.1893 47.4665 29.7114 45.8819 31.0659 43.9187"
        stroke="white"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default EnergyPump;
