import React, { useEffect, useState } from "react";
import cx from "classnames";
import { Button } from "../../../../style/Button";
import { Controller, useForm } from "react-hook-form";
import { CheckBox, Radio, Select, Text } from "../../../Forms";
import Tooltip from "../../../Tooltip";
import { useGetWhatToExpectCalcParameters } from "../../../../hooks/useGetWhatToExpectCalcParameters";
import { convertFromToUnits, formatConsumption } from "../../../../formUtils";
import { KWH, M3, MWH } from "../../../../constants";
import { formatElectricityHeatConsumption, getHeatPumpParamByHeatConsumption } from "../../../../helpers/WhatToExpect";
import { ZIP_LENGTH, zipValidation } from "../../../Forms/helpers/zipValidation";
import {consumptionValidation, MAX_CONSUMPTION_KWH} from "../../../Forms/helpers/consumptionValidation";
import {onlyFloatNumber, onlyNumber} from "../../../Forms/helpers/onlyNumber";

export const FORM_TYPE = {
  CONSUMPTION: "consumption",
  ADVANCE: "advance",
};

const unitTypes = [
  { title: "MWh", value: MWH },
  { title: "kWh", value: KWH },
  { title: "m³", value: M3 },
];

const defaultValues = {
  unit: MWH,
  formType: "consumption",
};

const formTypeRadios = {
  name: "formType",
  fields: [
    {
      label: "spotřeby plynu (přesnější)",
      value: FORM_TYPE.CONSUMPTION,
    },
    {
      label: (
        <div className={"flex items-center"}>
          současných záloh za plyn{" "}
          <Tooltip
            elementClassNames={"ml-1"}
            text={
              <div>
                Vaše současná záloha může být nastavena na základě staršího fixovaného ceníku, proto přepočet na MWh může být zkreslen oproti skutečnosti.
              </div>
            }
          />
        </div>
      ),
      value: FORM_TYPE.ADVANCE,
    },
  ],
};

const heatLossTooltipText = loss => `Odhadovaná tepelná ztráta vaší nemovitosti je ${loss} kW.`;

const HeatPumpCalc = ({ isMNDCustomChecked, updateCalculation, gasOfferCode, eleOfferCode }) => {
  const [calculating, setCalculating] = useState(false);

  const { handleSubmit, control, register, watch, errors, getValues } = useForm({
    defaultValues,
  });
  const onSubmit = data => {
    setCalculating(true);
  };

  const formTypeValue = watch("formType");
  const zipCodeValue = watch("zip");
  const advanceValue = watch("advance");
  const consumptionValue = watch("consumption");
  const unitValue = watch("unit");
  const mndCustomerValue = watch("mndCustomer");

  const { elePrice, gasPrice, consumptionForCalculation, loading, loadingResult, zipCodeValid, apiError, gasPriceListUrl, elePriceListUrl } = useGetWhatToExpectCalcParameters({
    calculating,
    formType: formTypeValue,
    advance: advanceValue,
    consumption: convertFromToUnits(consumptionValue, unitValue, KWH),
    zipCode: zipCodeValue,
    gasOfferCode,
    eleOfferCode,
  });

  useEffect(() => {
    if (!loading && !!elePrice && !!gasPrice && calculating && !!gasPriceListUrl && !!elePriceListUrl) {
      updateCalculation({
        touched: true,
        elePrice,
        gasPrice,
        consumptionForCalculation,
        heatPumpPrice: getHeatPumpParamByHeatConsumption(consumptionForCalculation).price,
        mndCustomer: mndCustomerValue,
        gasPriceListUrl,
        elePriceListUrl
      });
      setCalculating(false);
    }
  }, [updateCalculation, elePrice, gasPrice, consumptionForCalculation, loading, gasPriceListUrl, elePriceListUrl]);

  const isConsumptionForm = formTypeValue === formTypeRadios.fields.find(item => item.value === FORM_TYPE.CONSUMPTION).value;
  const isAdvanceForm = formTypeValue === formTypeRadios.fields.find(item => item.value === FORM_TYPE.ADVANCE).value;

  const zipHasError = zipCodeValid === false || !!errors?.zip?.message;
  
  const itIsHouseholdConsumption = consumptionForCalculation < MAX_CONSUMPTION_KWH;

  return (
    <div className={"relative bg-light-grey"}>
      <div className={"px-0 md:px-4 max-w-screen-lg mx-auto"}>
        <form onSubmit={handleSubmit(onSubmit)} className={"relative top-0 md:-top-12 flex flex-col px-4 md:px-8 py-8 bg-white"}>
          <h4 className={"text-H4 text-exploratory-green-400"}>Spočítejte si podle</h4>

          <div className={"flex flex-col md:flex-row mb-3"}>
            {formTypeRadios.fields.map(item => (
              <Radio
                key={item.value}
                name={formTypeRadios.name}
                label={item.label}
                value={item.value}
                register={register}
                isSelected={formTypeValue === item.value}
                wrapperAdditionalClassNames={"mr-6 mb-2 md:mb-0"}
              />
            ))}
          </div>

          <div className={"flex flex-col md:flex-row border border-t border-b-0 border-r-0 border-l-0 border-solid border-grey mb-3"}>
            {isConsumptionForm && (
              <div className={"flex items-end p-3 pl-0 border border-t-0 border-b-0 border-l-0 border-solid border-white md:border-grey max-w-[310px]"}>
                <div
                  className={cx({
                    "mr-2": true,
                    "mb-10": !errors?.consumption?.message && zipHasError,
                  })}
                >
                  <Controller
                    as={<Text label={"Vaše roční spotřeba"} normalization={onlyFloatNumber} error={errors?.consumption?.message} />}
                    control={control}
                    name={"consumption"}
                    rules={{
                      required: "Vyplňte prosím vaší roční spotřebu plynu",
                      validate: {
                        consumptionValidation: (value, values) => {
                          return consumptionValidation(value, getValues().unit);
                        },
                      },
                    }}
                  />
                </div>
                <div
                  className={cx({
                    "min-w-[95px]": true,
                    "mb-10": !!errors?.consumption?.message || zipHasError,
                  })}
                >
                  <Controller control={control} name={"unit"} options={unitTypes} as={<Select />} />
                </div>
              </div>
            )}
            {isAdvanceForm && (
              <div
                className={"flex items-end p-3 pl-0 border border-t-0 border-b-0 border-r-1 border-l-0 border-solid border-white md:border-grey max-w-[214px]"}
              >
                <div
                  className={cx({
                    "mr-2": true,
                    "mb-10": !errors?.advance?.message && zipHasError,
                  })}
                >
                  <Controller
                    control={control}
                    name={"advance"}
                    as={<Text normalization={onlyNumber} label={"Vaše měsíční záloha"} />}
                    error={errors?.advance?.message}
                    rules={{
                      required: "Vyplňte prosím vaší měsíční zálohu za plyn",
                    }}
                  />
                </div>
                <div
                  className={cx({
                    "font-semibold text-exploratory-green-400": true,
                    "mb-12": zipHasError,
                    "mb-1.5": !zipHasError,
                  })}
                >
                  Kč
                </div>
              </div>
            )}

            <div
              className={
                "border py-3 px-0 md:px-3 border-t-0 border-b-0 border-r-1 border-l-0 border-solid border-white md:border-grey max-w-[174px] md:max-w-none"
              }
            >
              <Controller
                rules={{
                  required: "Vyplňte prosím PSČ odběrného místa",
                  minLength: {
                    value: ZIP_LENGTH,
                    message: "Je potřeba zadat validní PSČ",
                  },
                  maxLength: {
                    value: ZIP_LENGTH,
                    message: "Je potřeba zadat validní PSČ",
                  },
                }}
                as={<Text label={"PSČ odběrného místa"} />}
                control={control}
                name={"zip"}
                normalization={zipValidation}
                error={errors?.zip?.message || (zipCodeValid === false ? "Vyplňte prosím platné PSČ" : null)}
              />
            </div>

            {/*<div className={"flex items-end py-3 px-0 md:px-3"}>*/}
            {/*  <div className={"mb-2"}>*/}
            {/*    <Controller control={control} name={"mndCustomer"} defaultValue={isMNDCustomChecked} as={<CheckBox label={"Odebírám plyn od MND"} />} />*/}
            {/*  </div>*/}
            {/*</div>*/}
          </div>

          {!!consumptionForCalculation && (
            <div className={"hidden md:block"}>
              {isAdvanceForm && (
                <h4 className={"text-link font-semibold text-exploratory-green-400 mb-1"}>
                  <div className={"flex items-center"}>
                    Námi odhadovaná spotřeba <br className={"block md:hidden"} /> {formatConsumption(convertFromToUnits(consumptionForCalculation, KWH, MWH))}{" "}
                    MWh{" "}
                    <Tooltip
                      elementClassNames={"ml-2.5"}
                      text={<div>{heatLossTooltipText(getHeatPumpParamByHeatConsumption(consumptionForCalculation).heatLoss)}</div>}
                    />
                  </div>
                </h4>
              )}

              {itIsHouseholdConsumption ? (
                <p className={"text-tiny text-exploratory-green-400 mb-0"}>
                  Odpovídá spotřebě tepelného čerpadla <br className={"block md:hidden"} />
                  {formatElectricityHeatConsumption(consumptionForCalculation)} v nízkém tarifu (zdroj: TZB info)
                </p>
              ) : (
                <p className={"text-tiny text-exploratory-green-400 mb-0"}>
                  Zadaná spotřeba neodpovídá typické domácnosti, uveďte spotřebu nižší než 64 MWh/rok.
                </p>
              )}
            </div>
          )}
          
          <div className={"w-full flex justify-center mt-0 md:mt-6"}>
            {loading && !apiError && (
              <Button variant={"disabled"} width={"165px"}>
                {loadingResult ? "Počítám" : "Spočítat"}
              </Button>
            )}

            {!loading && !apiError && <Button width={"165px"}>Spočítat</Button>}

            {apiError && <p className={"text-red-500"}>Došlo k chybě serveru, kalkulaci nelze spočítat</p>}
          </div>
        </form>
      </div>
    </div>
  );
};

export default HeatPumpCalc;
