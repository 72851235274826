import React, { useEffect, useState } from "react";
import { graphql, Link } from "gatsby";

import MainLayout from "../componentsTailwind/MainLayout";

import config from "../configs";
import { Helmet } from "react-helmet";
import GasPayOffBanner from "../componentsTailwind/Banners/GasPayOffBanner";
import HeatPumpSummaryWhatNeedsToBeConsidered from "../componentsTailwind/LandingPages/WhatToExpect/components/HeatPumpSummaryWhatNeedsToBeConsidered";
import HeatPumpCalcResult from "../componentsTailwind/LandingPages/WhatToExpect/components/HeatPumpCalcResult";
import HeatPumpSummary from "../componentsTailwind/LandingPages/WhatToExpect/components/HeatPumpSummary";
import HeatPumpCalc from "../componentsTailwind/LandingPages/WhatToExpect/components/HeatPumpCalc";
import SolarPanelAndHeatPumpCTA from "../componentsTailwind/SolarPanelAndHeatPumpCTA/SolarPanelAndHeatPumpCTA";

export default ({ data }) => {
  const [calcResult, setCalcResult] = useState({
    touched: false,
    gasPrice: 0,
    elePrice: 0,
    gasPriceListUrl: '',
    elePriceListUrl: '',
  });

  useEffect(() => {
    setTimeout(() => {
      const element = document.getElementById("calcResult");
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }, 500);
  }, [calcResult.gasPrice, calcResult.elePrice]);

  const bannerTitle = data?.mnd?.whatShouldIExpect?.data?.attributes?.BannerTitle;
  const bannerText = data?.mnd?.whatShouldIExpect?.data?.attributes?.BannerText;

  const gasOfferCode = data?.mnd?.whatShouldIExpect?.data?.attributes?.gasOfferCode;
  const eleOfferCode = data?.mnd?.whatShouldIExpect?.data?.attributes?.eleOfferCode;

  const isMNDCustomChecked = data?.mnd?.whatShouldIExpect?.data?.attributes?.defaultValueMndCustomer;
  
  return (
    <MainLayout>
      <Helmet>
        <title>Porovnání topení plynem a tepelným čerpadlem</title>
        <meta name="keywords" content="změna dodavatele plynu, změna dodavatele elektřiny, změna dodavatele energie, změna dodavatele energií" />
        <meta name="description" content={"Zjistěte si, kolik zaplatíte za topení plynem nebo tepelným čerpadlem vzduch-voda."} />
        <meta name="og:description" content={"Zjistěte si, kolik zaplatíte za topení plynem nebo tepelným čerpadlem vzduch-voda."} />
        <meta property="og:image" content={`${config.serverUrl}/images/og-image-hp.png`} />
        <link rel="canonical" href="https://www.mnd.cz/kalkulacka-tepelne-cerpadlo"></link>
      </Helmet>
      <GasPayOffBanner title={bannerTitle} text={bannerText} />

      <HeatPumpCalc isMNDCustomChecked={isMNDCustomChecked} updateCalculation={setCalcResult} gasOfferCode={gasOfferCode} eleOfferCode={eleOfferCode} />

      <div className={"max-w-screen-lg mx-auto px-4 pt-6"}>
        {!calcResult.touched && <HeatPumpSummaryWhatNeedsToBeConsidered />}
        {calcResult.touched && <HeatPumpCalcResult heatPumpPrice={calcResult?.heatPumpPrice} calcResult={calcResult} />}
      </div>

      {/*{calcResult.touched && !calcResult.mndCustomer && <HeatPumpCTA />}*/}


      <div className="mb-6 md:mb-12">
          <SolarPanelAndHeatPumpCTA showSolarPanel={false} showHeatPump={true} isDetail={true} />
      </div>

      <div className={"max-w-screen-lg mx-auto px-4 pt-6 pb-10 sm:pb-14"}>
        {calcResult.touched && <HeatPumpSummary heatPumpPrice={calcResult?.heatPumpPrice} calcResult={calcResult} />}
      </div>
    </MainLayout>
  );
};

// export const query = graphql`
//   query WhatShouldIExpectQuery($articleIds: [Int]) {
//     allNews(filter: { newsId: { in: $articleIds } }) {
//       edges {
//         node {
//           releaseDate {
//             date
//           }
//           newsId
//           id
//           name
//           slug
//           perex
//           promolink
//           calculatorType
//           sidebarCalculatorType
//           content
//           type
//           descriptionImage
//         }
//       }
//     }

export const query = graphql`
  query WhatShouldIExpectQuery {
    mnd {
      whatShouldIExpect {
        data {
          attributes {
            BannerText
            BannerTitle
            defaultValueMndCustomer
            gasOfferCode
            eleOfferCode
          }
        }
      }
    }
  }
`;
