import React from "react";
import PropTypes from "prop-types";
import EnergyPump from "./Ilustrations/energyPump";
import Gas from "../../../../style/icons/gas";
import Electricity from "../../../../style/icons/electricity";
import { theme } from "../../../../style/theme";
import Tooltip from "../../../Tooltip";
import { convertFromToUnits, formatConsumption, formatPrice } from "../../../../formUtils";
import { formatElectricityHeatConsumption } from "../../../../helpers/WhatToExpect";
import { ELECTRICITY, GAS, KWH, MWH } from "../../../../constants";
import BarGraph3D from "./BarGraph3D";
import PdfViewer from "../../../../components/PdfViewer/PdfViewer";

const PriceBox = ({ price, description, detailDescription, showDetailResult }) => {
  return (
    <div className={"text-center mt-2"}>
      <p className={"mb-0 font-semibold"}>{description}</p>
      <p className={"flex items-center justify-center mb-0 font-bold"}>{price}</p>
      {detailDescription && showDetailResult && <p className={"text-body"} dangerouslySetInnerHTML={{ __html: detailDescription }} />}
    </div>
  );
};

const Roof = () => (
  <div className={"absolute -top-16 -right-4 -left-4 lg:-left-6 lg:-right-6  h-[65px]"}>
    <svg viewBox="0 0 363 65" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1.5 63L181.5 3L361.5 63" stroke="#E6E6E7" strokeWidth="4" />
    </svg>
  </div>
);

const CalcGraphResult = ({
  showDetailResult,
  calcResult: { heatPumpPrice, gasPrice, elePrice, consumptionForCalculation, gasPriceListUrl, elePriceListUrl },
}) => {
  const gasScale = gasPrice / Math.max(gasPrice, elePrice);
  const electricityScale = elePrice / Math.max(gasPrice, elePrice);

  return (
    <div className={"flex flex-col md:flex-row items-center justify-between mb-4 text-exploratory-green-400"}>
      <div className={"relative max-w-[320px] w-full border-t-1 border-4 border-1 border-t-0 border-solid border-light-grey px-4 pb-6 mt-20"}>
        <Roof />

        <h3 className={"text-H4 text-center"}>Plyn</h3>

        <div className={"flex flex-col items-center"}>
          <div className={"flex items-end h-[180px]"}>
            <BarGraph3D type={GAS} scale={gasScale} />
          </div>
          <PriceBox
            price={formatPrice(gasPrice)}
            description={"Platba za plyn"}
            detailDescription={`při roční spotřebě<br />${formatConsumption(convertFromToUnits(consumptionForCalculation, KWH, MWH))} MWh`}
            showDetailResult={showDetailResult}
          />
        </div>

        {showDetailResult && (
          <div className={"text-center"}>
            <span className={"flex items-center justify-center text-small text-exploratory-green-400 font-semibold"}>
              <Gas color={theme.colors.exploratoryGreen} width="22" height="22" />
              <PdfViewer
                linkText={"Plyn z první ruky - základní ceník"}
                srcURL={gasPriceListUrl}
                commodity={"gas"}
                offerCode={"PZR_FIX"} // PZR_FIX_II
                useImage
              />
              <Tooltip
                elementClassNames={"ml-1"}
                maxWidth={250}
                jsx
                text={`K uvedené obchodní ceně se připočítávají i regulované ceny distribuce, které odpovídají dané modelové spotřebě.`}
              />
            </span>
          </div>
        )}
      </div>

      <p className={"text-H3 font-bold mt-3 mb-0"}>vs.</p>

      <div className={"relative max-w-[320px] w-full border-t-1 border-4 border-t-0 border-solid border-light-grey px-4 pb-6 mt-20"}>
        <Roof />

        <h3 className={"text-H4 text-center"}>Tepelné čerpadlo</h3>

        <div className={"flex items-end justify-between"}>
          <div className={"flex flex-col items-center"}>
            <div className={"flex items-end h-[180px]"}>
              <BarGraph3D type={ELECTRICITY} scale={electricityScale} />
            </div>

            <PriceBox
              price={formatPrice(elePrice)}
              description={"Platba za elektřinu"}
              detailDescription={`při roční spotřebě<br />NT ${formatElectricityHeatConsumption(consumptionForCalculation)}`}
              showDetailResult={showDetailResult}
            />
          </div>

          <div className={"relative flex flex-col items-center"}>
            <span className={"absolute top-5 -left-9 font-bold text-H4"}>+</span>
            <EnergyPump />
            <PriceBox
              price={
                <>
                  {formatPrice(heatPumpPrice)}
                  <Tooltip
                    elementClassNames={"ml-1 -mt-1"}
                    text={`Pořizovací cena tepelného čerpadla 
                  (technologie vzduch – voda) byla stanovena na základě vámi uvedené roční spotřeby plynu. 
                  Přiřadili jsme vám tak orientační výkonnost tepelného čerpadla a jeho odpovídající 
                  průměrnou pořizovací tržní cenu. (Zdroj: TZB-info)`}
                  />
                </>
              }
              description={"Pořizovací cena"}
              detailDescription={"<br /><br />"}
              showDetailResult={showDetailResult}
            />
          </div>
        </div>

        {showDetailResult && (
          <div className={"text-center"}>
            <span className={"flex items-center justify-center text-small text-exploratory-green-400 font-semibold"}>
              <Electricity color={theme.colors.exploratoryGreen} width="22" height="22" />
              <PdfViewer
                linkText={"Proud - Domácnosti - základní ceník"}
                srcURL={elePriceListUrl}
                commodity={"electricity"}
                offerCode={"PROUD_FIX"} // PROUD_FIX_II
                useImage
              />
              <Tooltip
                elementClassNames={"ml-1"}
                maxWidth={250}
                jsx
                text={`K uvedené obchodní ceně se připočítávají i regulované ceny distribuce, které odpovídají dané modelové spotřebě.`}
              />
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

CalcGraphResult.propTypes = {
  showDetailResult: PropTypes.bool.isRequired,
};

export default CalcGraphResult;
