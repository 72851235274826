import accounting from "accounting";
import { GAS, KWH, M3, MWH } from "../constants";
import config from "../configs";

export const hasVariableAmperes = (breaker, breakers) => {
  const selectedBreaker = breakers.find(item => item.value === breaker);
  return selectedBreaker ? selectedBreaker.variableAmperes : false;
};

export const hasLowTariff = (rate, rates) => {
  const selectedRate = rates.find(item => item.value === parseInt(rate, 10));
  return selectedRate && selectedRate.allows_both_tariffs ? selectedRate.allows_both_tariffs : null;
};

export const goToPriceCalculation = (baseUrl, urlData, urlPart = "") => {
  window.location = `${baseUrl}#/${urlPart}?d=${encodeURIComponent(JSON.stringify(urlData))}`;
};

export const formatConsumption = num => {
  return accounting.formatMoney(num, "", 2, " ", ",");
};

export function convertFromToUnits(value, valueInUnits, newUnits) {
  let valueInKwh = null;
  const kilos = 1000;
  const mConvert = 10.55;

  if (valueInUnits === MWH) {
    valueInKwh = value * kilos;
  }

  if (valueInUnits === KWH) {
    valueInKwh = value;
  }

  if (valueInUnits === M3) {
    valueInKwh = value * mConvert;
  }

  if (newUnits === KWH) {
    return Math.round(valueInKwh);
  }

  if (newUnits === MWH) {
    return Math.round((valueInKwh * 1000) / kilos) / 1000;
  }

  if (newUnits === M3) {
    return Math.round((valueInKwh * 100) / mConvert) / 100;
  }

  return valueInKwh;
}

export const contactTimes = {
  morning: [
    { value: 800, label: "8:00" },
    { value: 815, label: "8:15" },
    { value: 830, label: "8:30" },
    { value: 845, label: "8:45" },
    { value: 900, label: "9:00" },
    { value: 915, label: "9:15" },
    { value: 930, label: "9:30" },
    { value: 945, label: "9:45" },
    { value: 1000, label: "10:00" },
    { value: 1015, label: "10:15" },
    { value: 1030, label: "10:30" },
    { value: 1045, label: "10:45" },
    { value: 1100, label: "11:00" },
    { value: 1115, label: "11:15" },
    { value: 1130, label: "11:30" },
    { value: 1145, label: "11:45" },
    { value: 1200, label: "12:00" },
    { value: 1215, label: "12:15" },
    { value: 1230, label: "12:30" },
    { value: 1245, label: "12:45" },
  ],
  afternoon: [
    { value: 1300, label: "13:00" },
    { value: 1315, label: "13:15" },
    { value: 1330, label: "13:30" },
    { value: 1345, label: "13:45" },
    { value: 1400, label: "14:00" },
    { value: 1415, label: "14:15" },
    { value: 1430, label: "14:30" },
    { value: 1445, label: "14:45" },
    { value: 1500, label: "15:00" },
    { value: 1515, label: "15:15" },
    { value: 1530, label: "15:30" },
    { value: 1545, label: "15:45" },
    { value: 1600, label: "16:00" },
    { value: 1615, label: "16:15" },
    { value: 1630, label: "16:30" },
    { value: 1645, label: "16:45" },
    { value: 1700, label: "17:00" },
    { value: 1715, label: "17:15" },
    { value: 1730, label: "17:30" },
    { value: 1745, label: "17:45" },
  ],
};

/**
 * @param {Number} dayNumber cislo dne v tydnu
 * @returns {string} den v tydnu
 */
export const getDayName = dayNumber => {
  const days = ["Neděle", "Pondělí", "Úterý", "Středa", "Čtvrtek", "Pátek", "Sobota", "Neděle"];
  return days[dayNumber] ? days[dayNumber] : "";
};

export const formatPrice = price => {
  return accounting.formatMoney(price, "", 0, " ", ",") + " Kč";
};

export const formatLink = (formLink, stoken, city, zipCode, dealerId, consumption, commodity) => {
  let stokenPart = stoken ? `&stoken=${stoken}` : "";

  return `${config.gofu}${commodity === GAS ? "plyn" : "elektrina"}${
    formLink.match(/\?/) ? formLink : formLink + "?"
  }${stokenPart}&c=${city}&z=${zipCode}&d=${dealerId}&co=${consumption}&u=mwh`;
};

/* eslint-disable no-magic-numbers */

/**
 * Prevodni pole mezi nasim cislem kraje a krajem podle ciselniku pro ceniky
 * @type {{1: number, 2: number, 3: number, 4: number, 5: number, 6: number, 7: number, 8: number, 9: number, 10: number, 11: number, 12: number, 13: number, 14: number}}
 */
export const REGION_CONVERT = {
  1: 51,
  2: 27,
  3: 35,
  4: 116,
  5: 86,
  6: 94,
  7: 132,
  8: 78,
  9: 124,
  10: 108,
  11: 60,
  12: 141,
  13: 43,
  14: 19,
};

/**
 *
 * @param {number} zipCodeRegion id regionu dle databaze regionu pro location
 * @returns {number} id regionu pro api cally
 */
export function regionConvert(zipCodeRegion) {
  return REGION_CONVERT[zipCodeRegion];
}

export function formatPostCode (postCode) {
  const postCodeSegs = String(postCode).split('');
  postCodeSegs.splice(3, 0, ' ');
  return postCodeSegs.join('');
};