import React from "react";
import cx from "classnames";
import { default as TooltipComponent } from "rc-tooltip";

const Tooltip = props => {
  const { text, elementClassNames, maxWidth, jsx } = props;
  
  
  return (
    <TooltipComponent placement="top" overlayStyle={{ maxWidth: maxWidth }} overlay={jsx ? <div dangerouslySetInnerHTML={{ __html: text }} /> : <div>{text}</div>}>
      <i
        className={cx({
          "relative w-4 h-4": true,
          [elementClassNames]: true,
        })}
      >
        <span className={"block absolute w-4 h-4 top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 not-italic text-tiny text-white"}>
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="8" cy="8" r="8" fill="#ACAEB0" />
            <path d="M8 12V7" stroke="white" strokeWidth="2" />
            <path d="M8 6V4" stroke="white" strokeWidth="2" />
          </svg>
        </span>
      </i>
    </TooltipComponent>
  );
};

Tooltip.defaultProps = {
  maxWidth: 180,
  jsx: false,
}

export default Tooltip;
