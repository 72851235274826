import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import Radio from "./Radio";

const Checkbox = props => {
  const { name, label, checked, onChange, inputClassName, labelClassNames, wrapperAdditionalClassNames } = props;

  return (
    <div
      className={cx({
        "flex items-center": true,
        [wrapperAdditionalClassNames]: !!wrapperAdditionalClassNames,
      })}
    >
      <div className="flex h-5 items-center">
        <input
          name={name}
          id={name}
          type="checkbox"
          onChange={e => onChange(e.target.checked)}
          checked={checked}
          className={cx({
            "h-5 w-5 border-2 border-solid border-expertise-gray text-exploratory-green-400 bg-grey focus:ring-transparent cursor-pointer": true,
            "text-exploratory-green-400": checked,
            [inputClassName]: true,
          })}
        />
      </div>
      <div className="ml-3 text-sm">
        <label
          htmlFor={name}
          className={cx({
            "font-semibold font-link cursor-pointer": true,
            "text-expertise-gray": !checked,
            "text-exploratory-green-400": checked,
            [labelClassNames]: true,
          })}
        >
          {label}
        </label>
      </div>
    </div>
  );
};

Radio.defaultProps = {
  inputClassName: "",
  labelClassName: "",
};

Radio.propTypes = {
  inputClassName: PropTypes.string,
  labelClassName: PropTypes.string,
  label: PropTypes.string.isRequired,
};

export default Checkbox;
