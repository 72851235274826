import React from "react";
import IconLightBubble from "../../../Icons/IconLightBubble";

const HeatPumpSummaryWhatNeedsToBeConsidered = () => {
  return (
    <div className={"relative bg-resource-green-100 text-exploratory-green-400 px-4"}>
      <div className={"flex flex-col justify-center py-6 md:py-16 container mx-auto max-w-screen-lg max-w-[620px]"}>
        <h2 className={"text-H3 flex items-center mb-8"}>
          <IconLightBubble width={50} />
          <span className={"inline ml-3"}>Co je třeba zvážit?</span>
        </h2>

        <h3 className={"text-H4"}>Zvolte optimální technologické řešení</h3>
        <p className={"mb-12 text-bigForms"}>
          Tepelné čerpadlo technologie vzduch-voda je vhodné jen pro některé typy nemovitostí, proto si nechte vždy připravit od dodavatele individuální nabídku
          s optimálním technologickým řešením.
        </p>

        <h3 className={"text-H4"}>Tepelné čerpadlo neřeší aktuální nedostatek financí</h3>
        <p className={"text-bigForms"}>
          O tepelném čerpadle lze uvažovat, pokud máte aktuálně dostatek finančních prostředků a nespěcháte na návratnost investice. Pokud ale chcete šetřit
          hned, věnujte pozornost{" "}
          <a href={"/podpora/nejcastejsi-dotazy-spojene-s-rustem-energii/tipy-na-usporu-energii-v-domacnosti"} className={"underline hover:no-underline text-exploratory-green-400"}>
            našim tipům na okamžité úspory energií
          </a>
          .
        </p>
      </div>
    </div>
  );
};

export default HeatPumpSummaryWhatNeedsToBeConsidered;
