import React from "react";
import PropTypes from "prop-types";

const Text = props => {
  const { name, type, label, onChange, value, id, normalization, error } = props;

  const htmlForStr = !!id ? id : name;

  return (
    <div className="flex flex-col">
      {label && (
        <label htmlFor={htmlForStr} className="block font-semibold text-exploratory-green-400">
          {label}
        </label>
      )}
      <div className="mt-1">
        <input
          id={htmlForStr}
          name={name}
          onChange={event => {
            if (!!normalization) {
              return (event.target.value = onChange(normalization(event.target.value)));
            } else {
              return onChange(event.target.value);
            }
          }}
          value={value}
          type={type ? type : "text"}
          className="block w-full font-semibold border-0 bg-light-grey text-exploratory-green-400 focus:ring-transparent"
        />
      </div>
      {!!error && <div className={"mt-1 text-tiny text-red-600"}>{error}</div>}
    </div>
  );
};

Text.propTypes = {
  label: PropTypes.string,
  id: PropTypes.string,
};

export default Text;
