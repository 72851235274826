import { useEffect, useState } from "react";
import { getCities, getConsumptionByAdvance, getRetailOfferCodeIds } from "../api/api";
import { FORM_TYPE } from "../componentsTailwind/LandingPages/WhatToExpect/components/HeatPumpCalc";
import { DEFAULT_TARIFF_CIRCUIT_BREAKERS_ID, DEFAULT_ZIP } from "../constants";
import { convertHeatToElectricityConsumption } from "../helpers/WhatToExpect";

const API_GAS = "gas";
const API_ELECTRICITY = "ele";

const API_CONSUMPTION_KWH = "kwh"

const HEAT_PUMP_RATE_ID = 9; //D57d
const ADVANCE_MINIMUM_LIMIT = 200;

const zipCodeRegex = /^\d{5}$/i;

const useGetPricelistIds = ({ defaultGasOfferCode, calculationGasOfferCode, calculationElectricityOfferCode, setApiError }) => {
  const [loading, setLoading] = useState(null);
  const [results, setResults] = useState({
    defaultGasPricelist: null,
  });

  useEffect(() => {
    setLoading(true);
    (async () => {
      try {
        const pricelistIds = await getRetailOfferCodeIds({
          [API_GAS]: [defaultGasOfferCode, calculationGasOfferCode],
          [API_ELECTRICITY]: [calculationElectricityOfferCode],
        });
        setResults({
          defaultGasPricelist: pricelistIds[API_GAS][defaultGasOfferCode].id,
        });
      } catch (err) {
        setApiError(err);
      } finally {
        setLoading(false);
      }
    })();
  }, [setResults, setLoading]);

  return {
    loading,
    ...results,
  };
};

const useGetCalculatedConsumption = ({ enabled, zipCode, pricelistId, city, advance, zipCodeValid, setApiError }) => {
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState(null);

  useEffect(() => {
    if (!enabled || !advance || !zipCodeValid || advance < ADVANCE_MINIMUM_LIMIT) {
      setResults(0);
      return;
    }

    (async () => {
      setLoading(true);
      const consumptionResult = await getConsumptionByAdvance(API_GAS, {
        city: city,
        postcode: zipCode,
        advanceInCzk: advance,
        currentCommercialPriceListId: pricelistId,
      });
      try {
        setResults(consumptionResult.consumption);
      } catch (err) {
        setApiError(err);
      } finally {
        setLoading(false);
      }
    })();
  }, [enabled, zipCode, pricelistId, city, advance, setResults, setLoading]);
  return {
    loading,
    consumption: results,
  };
};

const useGetFirstCity = zipCode => {
  const [city, setCity] = useState(null);
  const [zipCodeValid, setZipCodeValid] = useState(null);
  const [loading, setLoading] = useState(null);

  useEffect(() => {
    setZipCodeValid(null);
  }, [zipCode]);

  useEffect(() => {
    if (!zipCode || !zipCodeRegex.test(zipCode)) {
      return;
    }

    zipCode &&
      (async () => {
        setLoading(true);
        const citiesApiResult = await getCities(zipCode);
        setZipCodeValid(citiesApiResult.valid);
        setCity(Object.keys(citiesApiResult.cities)[0]);
        setLoading(false);
      })();
  }, [zipCode, setLoading, setCity]);

  return {
    loading,
    zipCodeValid,
    city,
  };
};

const useGetGasElePrices = ({ consumption, zipCode, eleConsumption, enabled, gasOfferCode, eleOfferCode, setApiError }) => {
  const [gasPrice, setGasPrice] = useState(null);
  const [gasPriceListUrl, setGasPriceListUrl] = useState(null);
  const [elePrice, setElePrice] = useState(null);
  const [elePriceListUrl, setElePriceListUrl] = useState(null);
  const [loading, setLoading] = useState(null);

  useEffect(() => {
    if (!consumption || !zipCode || !zipCodeRegex.test(zipCode) || !enabled || !eleConsumption || !gasOfferCode || !eleOfferCode ) {
      return;
    }

    (async () => {
      setLoading(true);

      const gasData = {
        commodity: API_GAS,
        postcode: (zipCodeRegex.test(zipCode) && zipCode) || DEFAULT_ZIP,
        consumption,
        consumptionUnit: API_CONSUMPTION_KWH,
        offerCode: gasOfferCode,
        isCompany: false
      };

      const eleData = {
        commodity: API_ELECTRICITY,
        postcode: (zipCodeRegex.test(zipCode) && zipCode) || DEFAULT_ZIP,
        consumptionLowTariff: eleConsumption,
        consumptionHighTariff: 0.01,
        rateId: HEAT_PUMP_RATE_ID,
        circuitBreakerId: +DEFAULT_TARIFF_CIRCUIT_BREAKERS_ID,
        consumptionUnit: API_CONSUMPTION_KWH,
        offerCode: eleOfferCode,
        isCompany: false
      };

      const gasResponse = await fetch("/api/calculation", {
        method: "POST",
        body: JSON.stringify(gasData),
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
      });

      const eleResponse = await fetch("/api/calculation", {
        method: "POST",
        body: JSON.stringify(eleData),
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
      });


      const hasError = gasResponse?.status !== 200 || eleResponse?.status !== 200;
    
      if (hasError) {
        setApiError(true);
        return;
      }

      try {
        const eleResult = await eleResponse.json();
        const gasResult = await gasResponse.json();

        const hasError = gasResult?.status === 'failed' || eleResult?.status === 'failed';

        if (hasError) {
          setApiError(true);
          return;
        }

        const gasResultObj = gasResult?.result;
        const gasTotalPrice = gasResultObj?.mnd?.totalPrice;
        const gasTaxCoefficient = gasResultObj?.taxCoefficient;

        const gasPrice = Math.round(gasTotalPrice * gasTaxCoefficient);

        setGasPrice(gasPrice);
        setGasPriceListUrl(gasResultObj?.priceListLink);

        const eleResultObj = eleResult?.result;
        const eleTotalPrice = eleResultObj?.mnd?.totalPrice;
        const eleTaxCoefficient = eleResultObj?.taxCoefficient;
        const elePrice = Math.round(eleTotalPrice * eleTaxCoefficient);

        setElePrice(elePrice);
        setElePriceListUrl(eleResultObj.priceListLink);
      } catch (err) {
        setApiError(true);
      } finally {
        setLoading(false);
      }
    })();
  }, [consumption, zipCode, enabled, gasOfferCode, eleOfferCode]);

  return {
    loading,
    gasPrice,
    gasPriceListUrl,
    elePrice,
    elePriceListUrl
  };
};

export const useGetWhatToExpectCalcParameters = ({ calculating, formType, advance, consumption, zipCode, gasOfferCode, eleOfferCode }) => {
  const [apiError, setApiError] = useState();
  const [calculatedConsumption, setCalculatedConsumption] = useState(0);

  const { defaultGasPricelist, loading: pricelistLoading } = useGetPricelistIds({
    defaultGasOfferCode: "PZR_ZC",
    calculationGasOfferCode: "PZR_ZC",
    calculationElectricityOfferCode: "PROUD_ZC",
    setApiError,
  });

  const { city, loading: zipLoading, zipCodeValid } = useGetFirstCity(zipCode);
  const { consumption: calculatedConsumptionResult, loading: consumptionLoading } = useGetCalculatedConsumption({
    enabled: formType === FORM_TYPE.ADVANCE,
    zipCode,
    zipCodeValid,
    advance,
    pricelistId: defaultGasPricelist,
    city,
    setApiError,
  });

  useEffect(() => {
    setCalculatedConsumption(calculatedConsumptionResult);
  }, [calculatedConsumptionResult]);

  const { loading, gasPriceListUrl, gasPrice, elePriceListUrl, elePrice } = useGetGasElePrices({
    enabled: calculating,
    consumption: formType === FORM_TYPE.ADVANCE ? calculatedConsumption : consumption,
    eleConsumption: convertHeatToElectricityConsumption(formType === FORM_TYPE.ADVANCE ? calculatedConsumption : consumption),
    zipCode,
    setApiError,
    gasOfferCode,
    eleOfferCode
  });


  return {
    loading: loading || consumptionLoading || zipLoading || pricelistLoading,
    loadingResult: loading,
    gasPrice,
    elePrice,
    consumptionForCalculation: formType === FORM_TYPE.ADVANCE ? calculatedConsumption : consumption,
    apiError,
    zipCodeValid,
    gasPriceListUrl,
    elePriceListUrl,
  };
};
