import { convertFromToUnits, formatConsumption } from "../../formUtils";
import { KWH, MWH } from "../../constants";

export const HEAT_PUMP_FACTOR = 2.8;

export const convertHeatToElectricityConsumption = heat => {
  return Math.round((heat / HEAT_PUMP_FACTOR) * 10) / 10;
};

export const getHeatPumpParamByHeatConsumption = consumption => {
  const HEAT_GAS_CONSUMPTION_INDEX = 0;
  const HEAT_PUMP_PRICE_INDEX = 1;
  const HEAT_LOSS_INDEX = 2;

  const data = [
    [4400, 300000, 1.6],
    [6600, 300000, 2.4],
    [8800, 300000, 3.2],
    [11000, 300000, 4],
    [13200, 300000, 4.8],
    [15400, 350000, 5.6],
    [17600, 355000, 6.4],
    [19800, 360000, 7.2],
    [22000, 365000, 8],
    [24200, 370000, 8.8],
    [26400, 375000, 9.6],
    [28600, 380000, 10.4],
    [30800, 385000, 11.2],
    [33000, 390000, 12],
    [35200, 395000, 12.8],
    [37400, 400000, 13.6],
    [39600, 405000, 14.4],
    [41800, 410000, 15.2],
    [44000, 415000, 16],
  ];

  for (let i = data.length; i--; i > 0) {
    if (consumption > data[i][HEAT_GAS_CONSUMPTION_INDEX]) {
      return {
        price: data[i][HEAT_PUMP_PRICE_INDEX],
        heatLoss: data[i][HEAT_LOSS_INDEX],
      };
    }
  }
  return {
    price: data[0][HEAT_PUMP_PRICE_INDEX],
    heatLoss: data[0][HEAT_LOSS_INDEX],
  };
};

export const formatElectricityHeatConsumption = heatConsumption => {
  return formatConsumption(convertFromToUnits(convertHeatToElectricityConsumption(heatConsumption), KWH, MWH)) + " MWh";
};
