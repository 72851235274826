import React from "react";

const IconLightBubble = props => {
  return (
    <svg {...props} viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="25" cy="25" r="25" fill="white" />
      <path
        d="M24.9928 15.7504L24.9928 15.7503L24.9896 15.7505C20.5197 15.9359 16.9356 19.52 16.7502 23.9899L16.7499 23.9899L16.75 24.0026C16.7706 26.1847 17.6043 28.2856 19.1086 29.8723L19.1203 29.8846L19.1336 29.8953C20.0866 30.6596 20.6725 31.7913 20.75 33.0085C20.7545 33.6928 21.3147 34.2503 22 34.2503H28C28.686 34.2503 29.2466 33.6917 29.25 33.0065C29.308 31.792 29.8924 30.671 30.8452 29.9162L30.8562 29.9075L30.8662 29.8976C32.4345 28.3396 33.2906 26.2046 33.25 23.9979C33.2494 21.7417 32.3425 19.5786 30.7356 17.9923L30.7343 17.9911C29.2062 16.5043 27.1309 15.6884 24.9928 15.7504ZM29.1317 28.1051L28.9236 28.3169C27.9364 29.2416 27.2314 30.4359 26.8934 31.7503H23.1077C22.7529 30.3409 21.9791 29.071 20.8848 28.111C19.8538 26.9894 19.271 25.5256 19.2501 24.0045C19.3977 20.8875 21.8921 18.3948 25.0098 18.2501C26.4822 18.2027 27.9049 18.7478 28.9549 19.7786L28.9555 19.7793C30.0975 20.8925 30.7404 22.4085 30.75 24.0018H30.75L30.7501 24.0065C30.7884 25.5386 30.2044 27.0132 29.1317 28.1051Z"
        fill="#2B6E33"
        stroke="#2B6E33"
        strokeWidth="0.5"
      />
      <path
        d="M28 35.75H22C21.3119 35.75 20.75 36.3119 20.75 37C20.75 37.6881 21.3119 38.25 22 38.25H28C28.6881 38.25 29.25 37.6881 29.25 37C29.25 36.3119 28.6881 35.75 28 35.75Z"
        fill="#2B6E33"
        stroke="#2B6E33"
        strokeWidth="0.5"
      />
      <path
        d="M27 39.75H23C22.3119 39.75 21.75 40.3119 21.75 41C21.75 41.6881 22.3119 42.25 23 42.25H27C27.6881 42.25 28.25 41.6881 28.25 41C28.25 40.3119 27.6881 39.75 27 39.75Z"
        fill="#2B6E33"
        stroke="#2B6E33"
        strokeWidth="0.5"
      />
      <path
        d="M25 12.25C25.6881 12.25 26.25 11.6881 26.25 11V9C26.25 8.31193 25.6881 7.75 25 7.75C24.3119 7.75 23.75 8.31193 23.75 9V11C23.75 11.6881 24.3119 12.25 25 12.25Z"
        fill="#2B6E33"
        stroke="#2B6E33"
        strokeWidth="0.5"
      />
      <path
        d="M12 22.75H10C9.31193 22.75 8.75 23.3119 8.75 24C8.75 24.6881 9.31193 25.25 10 25.25H12C12.6881 25.25 13.25 24.6881 13.25 24C13.25 23.3119 12.6881 22.75 12 22.75Z"
        fill="#2B6E33"
        stroke="#2B6E33"
        strokeWidth="0.5"
      />
      <path
        d="M40 22.75H38C37.3119 22.75 36.75 23.3119 36.75 24C36.75 24.6881 37.3119 25.25 38 25.25H40C40.6881 25.25 41.25 24.6881 41.25 24C41.25 23.3119 40.6881 22.75 40 22.75Z"
        fill="#2B6E33"
        stroke="#2B6E33"
        strokeWidth="0.5"
      />
      <path
        d="M33.8937 13.3327L33.8937 13.3327L33.8931 13.3333C33.4054 13.821 33.4054 14.6093 33.8931 15.0969C34.1271 15.331 34.4396 15.4701 34.7798 15.4701C35.1137 15.4701 35.428 15.3455 35.6666 15.1069L35.6672 15.1063L37.0766 13.6869C37.0767 13.6868 37.0768 13.6867 37.0769 13.6866C37.5643 13.1989 37.5641 12.4109 37.0766 11.9233C36.5891 11.4358 35.8011 11.4357 35.3134 11.923C35.3133 11.9231 35.3132 11.9232 35.3131 11.9233L33.8937 13.3327Z"
        fill="#2B6E33"
        stroke="#2B6E33"
        strokeWidth="0.5"
      />
      <path
        d="M14.6871 11.9232L14.6803 11.9164L14.673 11.9101C14.1478 11.4599 13.3604 11.5229 12.9109 12.0468C12.51 12.5107 12.5102 13.2097 12.9113 13.6735L12.9169 13.68L12.923 13.6861L14.333 15.1061L14.3336 15.1067C14.5722 15.3453 14.8865 15.4699 15.2204 15.4699C15.5542 15.4699 15.8685 15.3453 16.1071 15.1067C16.5948 14.6191 16.5948 13.8308 16.1071 13.3432L14.6871 11.9232Z"
        fill="#2B6E33"
        stroke="#2B6E33"
        strokeWidth="0.5"
      />
    </svg>
  );
};

export default IconLightBubble;
