import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

const Radio = props => {
  const { register, name, label, inputClassNames, labelClassNames, wrapperAdditionalClassNames, value, isSelected } = props;

  const isChecked = !!isSelected;

  return (
    <div
      className={cx({
        "flex items-center": true,
        [wrapperAdditionalClassNames]: !!wrapperAdditionalClassNames,
      })}
    >
      <input
        ref={register}
        name={name}
        id={`${name}-${value}`}
        type="radio"
        value={value}
        className={cx({
          "h-5 w-5 mr-2 border-2 border-solid border-expertise-gray text-exploratory-green-400 bg-grey focus:ring-transparent cursor-pointer": true,
          "text-exploratory-green-400": isChecked,
          [inputClassNames]: true,
        })}
      />
      <label
        htmlFor={`${name}-${value}`}
        className={cx({
          "font-semibold font-link cursor-pointer": true,
          "text-expertise-gray": !isChecked,
          "text-exploratory-green-400": isChecked,
          [labelClassNames]: true,
        })}
      >
        {label}
      </label>
    </div>
  );
};

Radio.defaultProps = {
  inputClassNames: "",
  labelClassNames: "",
  wrapperAdditionalClassNames: "",
};

Radio.propTypes = {
  register: PropTypes.func.isRequired,
  inputClassNames: PropTypes.string,
  labelClassNames: PropTypes.string,
  wrapperAdditionalClassNames: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.any.isRequired,
  value: PropTypes.string.isRequired,
  isSelected: PropTypes.bool,
};

export default Radio;
